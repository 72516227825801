@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--input-checkbox-container-direction);
  --app-input-container-align-items: var(--input-checkbox-container-align-items);
  --app-input-container-justify-content: var(--input-checkbox-container-justify-content);
  --app-input-container-padding: var(--input-checkbox-container-padding);
  --app-input-container-border-width: var(--input-checkbox-container-border-width);
  --app-input-container-border-style: var(--input-checkbox-container-border-style);
  --app-input-container-border-color: var(--input-checkbox-container-border-color);

  --app-input-label-direction: var(--input-checkbox-label-direction);
  --app-input-label-justify-content: var(--input-checkbox-label-justify-content);
  --app-input-label-align-items: var(--input-checkbox-label-align-items);
  --app-input-label-margin: var(--input-checkbox-label-margin);

  --app-input-informer-margin: var(--input-checkbox-informer-margin);
}

.input {
  @extend %checkbox-reset;
}

.field {
  @at-root .input:checked + .icon {
    background: var(--app-custom-checkbox-on-bg, var(--input-checkbox-on-background-colour));
    border: var(--app-custom-checkbox-on-border, var(--input-checkbox-on-border));
    border-radius: var(--app-custom-checkbox-on-border-radius, var(--input-checkbox-on-border-radius));
    box-shadow: var(--app-custom-checkbox-on-box-shadow, var(--input-checkbox-on-box-shadow));
    height: var(--app-custom-checkbox-on-bg-size, var(--input-checkbox-on-background-size));
    width: var(--app-custom-checkbox-on-bg-size, var(--input-checkbox-on-background-size));

    svg {
      height: var(--app-custom-checkbox-on-icon-size, var(--input-checkbox-on-icon-size));
      width: var(--app-custom-checkbox-on-icon-size, var(--input-checkbox-on-icon-size));
    }

    path {
      fill: var(--app-custom-checkbox-on-icon-colour, var(--input-checkbox-on-icon-colour));
    }
  }

  @include outline-control('.input:focus + .icon');
}

.icon {
  background: var(--app-custom-checkbox-off-bg, var(--input-checkbox-off-background-colour));
  border: var(--app-custom-checkbox-off-border, var(--input-checkbox-off-border));
  border-radius: var(--app-custom-checkbox-off-border-radius, var(--input-checkbox-off-border-radius));
  box-shadow: var(--app-custom-checkbox-off-box-shadow, var(--input-checkbox-off-box-shadow));
  height: var(--app-custom-checkbox-off-bg-size, var(--input-checkbox-off-background-size));
  width: var(--app-custom-checkbox-off-bg-size, var(--input-checkbox-off-background-size));

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: var(--app-custom-checkbox-off-icon-size, var(--input-checkbox-off-icon-size));
    width: var(--app-custom-checkbox-off-icon-size, var(--input-checkbox-off-icon-size));
  }

  path {
    fill: var(--app-custom-checkbox-off-icon-colour, var(--input-checkbox-off-icon-colour));
  }
}

.label {
  flex: 1 1 100%;
  @include with-styled-text(input-checkbox-label);
  color: var(--app-custom-input-label-color);

  label {
    display: inline-block;
    width: 100%;
  }
}
