@import '../resources/vars';
@import '../resources/mixins';

.container {
  // TODO: discuss with design team about adding additional vars layer for fallbacks
  -webkit-font-smoothing: antialiased;
  --app-btn-align: var(--app-custom-btn-align);
  --app-btn-flex-direction: var(--app-custom-btn-flex-direction);
  --app-btn-border: var(--app-custom-btn-border);
  --app-btn-border-radius: var(--app-custom-btn-border-radius);
  --app-btn-margin: var(--app-custom-btn-margin);
  --app-btn-padding: var(--app-custom-btn-padding);
  --app-btn-height: var(--app-custom-btn-height);
  --app-btn-width: var(--app-custom-btn-width);
  --app-btn-max-width: var(--app-custom-btn-max-width);
  --app-btn-effect-disabled: var(--app-custom-btn-effect-disabled);

  @include respond-to(sm, up) {
    --app-btn-max-width-m: var(--app-custom-btn-max-width-m);
    --app-btn-height-m: var(--app-custom-btn-height-m);
  }

  @include respond-to(lg, up) {
    --app-btn-max-width-l: var(--app-custom-btn-max-width-l);
    --app-btn-height-l: var(--app-custom-btn-height-l);
  }

  // Button
  --app-btn-icon-display: var(--app-custom-btn-icon-display);
  --app-btn-icon-border: var(--app-custom-btn-icon-border);
  --app-btn-icon-border-radius: var(--app-custom-btn-icon-border-radius);
  --app-btn-icon-width: var(--app-custom-btn-icon-width);
  --app-btn-icon-height: var(--app-custom-btn-icon-height);
  --app-btn-icon-margin: var(--app-custom-btn-icon-margin);
  --app-btn-icon-size: var(--app-custom-btn-icon-size);
  --app-btn-background: var(--app-custom-btn-background);
  --app-btn-box-shadow: var(--app-custom-btn-box-shadow);
  --app-btn-icon-bg-color: var(--app-custom-btn-icon-bg-color);
  --app-btn-icon-color: var(--app-custom-btn-icon-color);

  // Label
  --app-btn-label-color: var(--app-custom-btn-label-color);
  --app-btn-label-face: var(--app-custom-btn-label-face);
  --app-btn-label-font-size: var(--app-custom-btn-label-font-size);
  --app-btn-label-font-style: var(--app-custom-btn-label-font-style);
  --app-btn-label-font-weight: var(--app-custom-btn-label-font-weight);
  --app-btn-label-letter-spacing: var(--app-custom-btn-label-letter-spacing);
  --app-btn-label-max-width: var(--app-custom-btn-label-max-width);
  --app-btn-label-padding: var(--app-custom-btn-label-padding);
  --app-btn-label-text-align: var(--app-custom-btn-label-text-align);
  --app-btn-label-text-transform: var(--app-custom-btn-label-text-transform);
  --app-btn-label-line-height: var(--app-custom-btn-label-line-height);
  --app-btn-label-text-decoration: var(--app-custom-btn-label-text-decoration);

  @include respond-to(sm, up) {
    --app-btn-label-font-size-m: var(--app-custom-btn-label-font-size-m);
  }

  @include respond-to(lg, up) {
    --app-btn-label-font-size-l: var(--app-custom-btn-label-font-size-l);
  }
}

.major {
  --app-custom-btn-background: var(--app-custom-btn-major-background, var(--btn-major-background));
  --app-custom-btn-border: var(--app-custom-btn-major-border, var(--btn-major-border));
  --app-custom-btn-box-shadow: var(--app-custom-btn-major-box-shadow, var(--btn-major-box-shadow));
  --app-custom-btn-label-color: var(--app-custom-btn-major-label-color, var(--btn-major-label-color));
  --app-custom-btn-label-font-weight: var(--app-custom-btn-major-label-font-weight, var(--btn-major-label-font-weight));
  --app-custom-btn-label-font-style: var(--app-custom-btn-major-label-font-style, var(--btn-major-label-font-style));
  --app-custom-btn-label-padding: var(--app-custom-btn-major-label-padding, var(--btn-major-label-padding));
  --app-btn-label-text-decoration: var(--app-custom-btn-major-label-text-decoration, var(--btn-major-label-text-decoration));
  --app-custom-btn-icon-display: var(--app-custom-btn-major-icon-display, var(--btn-major-icon-display));
  --app-custom-btn-icon-bg-color: var(--app-custom-btn-major-icon-bg-color, var(--btn-major-icon-bg-color));
  --app-custom-btn-icon-border: var(--app-custom-btn-major-icon-border, var(--btn-major-icon-border));
  --app-custom-btn-icon-color: var(--app-custom-btn-major-icon-color, var(--btn-major-icon-color));
  --app-custom-btn-icon-height: var(--app-custom-btn-major-icon-height, var(--btn-major-icon-height));
  --app-custom-btn-align: var(--app-custom-btn-major-align, var(--btn-major-align));
  --app-custom-btn-max-width: var(--app-custom-btn-major-max-width, var(--btn-major-max-width));
  --app-custom-btn-height: var(--app-custom-btn-major-height, var(--btn-major-height));

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-major-max-width-m, var(--btn-major-max-width-m));
    --app-custom-btn-height-m: var(--app-custom-btn-major-height-m, var(--btn-major-height-m));
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-major-max-width-l, var(--btn-major-max-width-l));
    --app-custom-btn-height-l: var(--app-custom-btn-major-height-l, var(--btn-major-height-l));
  }
}

.minor {
  --app-custom-btn-background: var(--app-custom-btn-minor-background, var(--btn-minor-background));
  --app-custom-btn-border: var(--app-custom-btn-minor-border, var(--btn-minor-border));
  --app-custom-btn-box-shadow: var(--app-custom-btn-minor-box-shadow, var(--btn-minor-box-shadow));
  --app-custom-btn-label-color: var(--app-custom-btn-minor-label-color, var(--btn-minor-label-color));
  --app-custom-btn-label-font-weight: var(--app-custom-btn-minor-label-font-weight, var(--btn-minor-label-font-weight));
  --app-custom-btn-label-font-style: var(--app-custom-btn-minor-label-font-style, var(--btn-minor-label-font-style));
  --app-custom-btn-label-padding: var(--app-custom-btn-minor-label-padding, var(--btn-minor-label-padding));
  --app-btn-label-text-decoration: var(--app-custom-btn-minor-label-text-decoration, var(--btn-minor-label-text-decoration));
  --app-custom-btn-icon-display: var(--app-custom-btn-minor-icon-display, var(--btn-minor-icon-display));
  --app-custom-btn-icon-bg-color: var(--app-custom-btn-minor-icon-bg-color, var(--btn-minor-icon-bg-color));
  --app-custom-btn-icon-border: var(--app-custom-btn-minor-icon-border, var(--btn-minor-icon-border));
  --app-custom-btn-icon-color: var(--app-custom-btn-minor-icon-color, var(--btn-minor-icon-color));
  --app-custom-btn-icon-height: var(--app-custom-btn-minor-icon-height, var(--btn-minor-icon-height));
  --app-custom-btn-align: var(--app-custom-btn-minor-align, var(--btn-minor-align));
  --app-custom-btn-max-width: var(--app-custom-btn-minor-max-width, var(--btn-minor-max-width));
  --app-custom-btn-height: var(--app-custom-btn-minor-height, var(--btn-minor-height));

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-minor-max-width-m, var(--btn-minor-max-width-m));
    --app-custom-btn-height-m: var(--app-custom-btn-minor-height-m, var(--btn-minor-height-m));
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-minor-max-width-l, var(--btn-minor-max-width-l));
    --app-custom-btn-height-l: var(--app-custom-btn-minor-height-l, var(--btn-minor-height-l));
  }
}

.negative {
  --app-custom-btn-background: var(--btn-negative-background);
  --app-custom-btn-border: var(--btn-negative-border);
  --app-custom-btn-box-shadow: var(--btn-negative-box-shadow);
  --app-custom-btn-label-color: var(--btn-negative-label-color);
  --app-custom-btn-label-font-weight: var(--btn-negative-label-font-weight);
  --app-custom-btn-label-font-style: var(--btn-negative-label-font-style);
  --app-custom-btn-label-padding: var(--btn-negative-label-padding);
  --app-btn-label-text-decoration: var(--btn-negative-label-text-decoration);
  --app-custom-btn-icon-display: var(--btn-negative-icon-display);
  --app-custom-btn-icon-bg-color: var(--btn-negative-icon-bg-color);
  --app-custom-btn-icon-border: var(--btn-negative-icon-border);
  --app-custom-btn-icon-color: var(--btn-negative-icon-color);
  --app-custom-btn-icon-height: var(--btn-negative-icon-height);
  --app-custom-btn-align: var(--app-custom-btn-negative-align, var(--btn-negative-align));
  --app-custom-btn-max-width: var(--app-custom-btn-negative-max-width, var(--btn-negative-max-width));
  --app-custom-btn-height: var(--btn-negative-height);

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-negative-max-width, var(--btn-negative-max-width-m));
    --app-custom-btn-height-m: var(--btn-negative-height-m);
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-negative-max-width, var(--btn-negative-max-width-l));
    --app-custom-btn-height-l: var(--btn-negative-height-l);
  }
}

.destructive {
  --app-custom-btn-background: var(--btn-destructive-background);
  --app-custom-btn-border: var(--btn-destructive-border);
  --app-custom-btn-box-shadow: var(--btn-destructive-box-shadow);
  --app-custom-btn-label-color: var(--btn-destructive-label-color);
  --app-custom-btn-label-font-weight: var(--btn-destructive-label-font-weight);
  --app-custom-btn-label-font-style: var(--btn-destructive-label-font-style);
  --app-custom-btn-label-padding: var(--btn-destructive-label-padding);
  --app-btn-label-text-decoration: var(--btn-destructive-label-text-decoration);
  --app-custom-btn-icon-display: var(--btn-destructive-icon-display);
  --app-custom-btn-icon-bg-color: var(--btn-destructive-icon-bg-color);
  --app-custom-btn-icon-border: var(--btn-destructive-icon-border);
  --app-custom-btn-icon-color: var(--btn-destructive-icon-color);
  --app-custom-btn-icon-height: var(--btn-destructive-icon-height);
  --app-custom-btn-align: var(--app-custom-btn-destructive-align, var(--btn-destructive-align));
  --app-custom-btn-max-width: var(--app-custom-btn-destructive-max-width, var(--btn-destructive-max-width));
  --app-custom-btn-height: var(--btn-destructive-height);

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-destructive-max-width, var(--btn-destructive-max-width-m));
    --app-custom-btn-height-m: var(--btn-destructive-height-m);
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-destructive-max-width, var(--btn-destructive-max-width-l));
    --app-custom-btn-height-l: var(--btn-destructive-height-l);
  }
}

.special {
  --app-custom-btn-background: var(--btn-special-background);
  --app-custom-btn-border: var(--btn-special-border);
  --app-custom-btn-box-shadow: var(--btn-special-box-shadow);
  --app-custom-btn-label-color: var(--btn-special-label-color);
  --app-custom-btn-label-font-weight: var(--btn-special-label-font-weight);
  --app-custom-btn-label-font-style: var(--btn-special-label-font-style);
  --app-custom-btn-label-padding: var(--btn-special-label-padding);
  --app-btn-label-text-decoration: var(--btn-special-label-text-decoration);
  --app-custom-btn-icon-display: var(--btn-special-icon-display);
  --app-custom-btn-icon-bg-color: var(--btn-special-icon-bg-color);
  --app-custom-btn-icon-border: var(--btn-special-icon-border);
  --app-custom-btn-icon-color: var(--btn-special-icon-color);
  --app-custom-btn-icon-height: var(--btn-special-icon-height);
  --app-custom-btn-align: var(--app-custom-btn-special-align, var(--btn-special-align));
  --app-custom-btn-max-width: var(--app-custom-btn-special-max-width, var(--btn-special-max-width));
  --app-custom-btn-height: var(--btn-special-height);

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-special-max-width, var(--btn-special-max-width-m));
    --app-custom-btn-height-m: var(--btn-special-height-m);
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-special-max-width, var(--btn-special-max-width-l));
    --app-custom-btn-height-l: var(--btn-special-height-l);
  }
}

.diminished{
  --app-custom-btn-background: var(--btn-diminished-background);
  --app-custom-btn-border: var(--btn-diminished-icon-border);
  --app-custom-btn-box-shadow: var(--btn-diminished-box-shadow);
  --app-custom-btn-label-color: var(--btn-diminished-label-color);
  --app-custom-btn-label-font-weight: var(--btn-diminished-label-font-weight);
  --app-custom-btn-label-font-style: var(--btn-diminished-label-font-style);
  --app-custom-btn-label-padding: var(--btn-diminished-label-padding);
  --app-btn-label-text-decoration: var(--btn-diminished-label-text-decoration);
  --app-custom-btn-icon-display: var(--btn-diminished-icon-display);
  --app-custom-btn-icon-bg-color: var(--btn-diminished-icon-bg-color);
  --app-custom-btn-icon-border: var(--btn-special-icon-border);
  --app-custom-btn-icon-color: var(--btn-diminished-icon-color);
  --app-custom-btn-icon-height: var(--btn-diminished-icon-height);
  --app-custom-btn-align: var(--app-custom-btn-diminished-align, var(--btn-diminished-align));
  --app-custom-btn-max-width: var(--app-custom-btn-diminished-max-width, var(--btn-diminished-max-width));
  --app-custom-btn-height: var(--btn-diminished-height);

  @include respond-to(sm, up) {
    --app-custom-btn-max-width-m: var(--app-custom-btn-diminished-max-width, var(--btn-diminished-max-width-m));
    --app-custom-btn-height-m: var(--btn-diminished-height-m);
  }

  @include respond-to(lg, up) {
    --app-custom-btn-max-width-l: var(--app-custom-btn-diminished-max-width, var(--btn-diminished-max-width-l));
    --app-custom-btn-height-l: var(--btn-diminished-height-l);
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: var(--app-btn-flex-direction, row);
  justify-content: var(--app-btn-align, var(--btn-align));
}

.container {
  border: var(--app-btn-border, var(--btn-border));
  border-radius: var(--app-btn-border-radius, var(--btn-border-radius));
  margin: var(--app-btn-margin, var(--btn-margin));
  padding: var(--app-btn-padding, var(--btn-padding));
  min-height: var(--app-btn-height, var(--btn-height));
  width: var(--app-btn-width, var(--btn-width));
  max-width: var(--app-btn-max-width, var(--btn-max-width));
  //on page /checklist/deposit-builder/accounts - issue with button bg and color
  background: var(--app-btn-background, var(--btn-major-background));
  color: var(--app-btn-label-color, var(--btn-major-label-color));

  @include respond-to(sm, up) {
    --app-btn-max-width: var(--app-btn-max-width-m, var(--btn-max-width-m));
    --app-btn-height: var(--app-btn-height-m, var(--btn-height-m))
  }

  @include respond-to(lg, up) {
    --app-btn-max-width: var(--app-btn-max-width-l, var(--btn-max-width-l));
    --app-btn-height: var(--app-btn-height-l, var(--btn-height-l))
  }

  &[aria-disabled="true"] {
    opacity: var(--app-btn-effect-disabled, var(--btn-effect-disabled));
    cursor: not-allowed;
  }

  &.companion {
    height: var(--btn-companion-height);
    justify-content: center;
    margin: var(--btn-companion-margin);
    width: var(--btn-companion-width);
  }

  &.major,
  &.minor,
  &.negative,
  &.destructive,
  &.special,
  &.diminished {
    border: var(--app-btn-border, var(--btn-border));
    box-shadow: var(--app-btn-box-shadow, var(--btn-major-box-shadow));

    .text {
      font-weight: var(--app-btn-label-font-weight, var(--btn-label-font-weight));
    }

    .icon {
      display: var(--app-btn-icon-display, var(--btn-icon-display));
      background-color: var(--app-btn-icon-bg-color, var(--btn-major-icon-bg-color));
      border: var(--app-btn-icon-border, var(--btn-icon-border));

      path {
        fill: var(--app-btn-icon-color, var(--btn-major-icon-color));
      }
    }
  }

  &.account {
    .icon {
      path {
        fill: #fff;
      }
    }
  }

  // TODO: Take a look later! Possible should be created a brand new component.
  &.action {
    border: var(--btn-action-border, none);
    border-radius: var(--btn-action-border-radius);
    background: var(--btn-action-background);
    padding: var(--btn-action-padding);
    margin: var(--btn-action-margin);
    flex-direction: var(--btn-action-flex-direction, row);

    .text {
      --btn-label-padding: 0;
      @include with-styled-text(btn-action-label);
    }

    .icon {
      --btn-icon-display: block;
      width: var(--btn-action-icon-size, 24px);
      height: var(--btn-action-icon-size, 24px);
      margin: var(--btn-action-icon-margin, 8px);

      path {
        fill: var(--btn-action-icon-colour, rgba(0, 0, 0, 0.7));
      }
    }
  }
}

.icon {
  align-items: center;
  display: var(--app-btn-icon-display, var(--btn-icon-display));
  flex-shrink: 0;
  justify-content: center;
}

.icon {
  @include with-accounts(background-color);
  border: var(--app-btn-icon-border, var(--btn-icon-border));
  border-radius: var(--app-btn-icon-border-radius, var(--btn-icon-border-radius));
  width: var(--app-btn-icon-width, var(--btn-icon-width));
  height: var(--app-btn-icon-height, var(--btn-icon-height));
  margin: var(--app-btn-icon-margin,  var(--btn-icon-margin));

  svg {
    height: var(--app-btn-icon-size, var(--btn-icon-size));
    width: var(--app-btn-icon-size, var(--btn-icon-size));
  }
}

.text {
  font-family: var(--app-btn-label-face, var(--btn-label-face));
  font-size: var(--app-btn-label-font-size, var(--btn-label-font-size));
  font-style: var(--app-btn-label-font-style, var(--btn-label-font-style));
  font-weight: var(--app-btn-label-font-weight, var(--btn-label-font-weight));
  letter-spacing: var(--app-btn-label-letter-spacing, var(--btn-label-letter-spacing));
  max-width: var(--app-btn-label-max-width, var(--btn-label-max-width));
  padding: var(--app-btn-label-padding, var(--btn-label-padding));
  text-align: var(--app-btn-label-text-align, var(--btn-label-text-align));
  text-transform: var(--app-btn-label-text-transform, var(--btn-label-text-transform));
  line-height: var(--app-btn-label-line-height, var(--btn-label-line-height));
  text-decoration: var(--app-btn-label-text-decoration, var(--btn-label-text-decoration));

  @include respond-to(sm, up) {
    --app-btn-label-font-size: var(--app-btn-label-font-size-m, var(--btn-label-font-size-m));
  }

  @include respond-to(lg, up) {
    --app-btn-label-font-size: var(--app-btn-label-font-size-l, var(--btn-label-font-size-l));
  }
}
