@use 'sass:list';
@use 'sass:map';

$breakpoints: null !default;
$screen: null !default;
$screen-max: null !default;

@mixin respond-to($name, $and-up: false, $and-to: false) {	
	@if $breakpoints {
		@if list.index($breakpoints, $name) == list.length($breakpoints) {
			@media screen and (min-width: map.get($screen, list.nth($breakpoints, list.length($breakpoints)))) {
				@content;
			}
		} @else if list.index($breakpoints, $name) == 1 {
			@if $and-to and map.get($screen-max, $and-to) {
				@media screen and (max-width: map.get($screen-max, $and-to)) {
					@content;
				}
			} @else {
				@media screen and (max-width: map.get($screen-max, list.nth($breakpoints, 1))) {
					@content;
				}
			}
		} @else {
			@if $and-up and map.get($screen, $name) {
				@media screen and (min-width: map.get($screen, $name)) {
					@content;
				}
			} @else if $and-to and map.get($screen, $name) and map.get($screen-max, $and-to) {
				@media screen and (min-width: map.get($screen, $name)) and (max-width: map.get($screen-max, $and-to)) {
					@content;
				}
			} @else if map.get($screen, $name) {
				@media screen and (min-width: map.get($screen, $name)) and (max-width: map.get($screen-max, $name)) {
					@content;
				}
			}
		}
	} @else {
		@warn 'There are not any breakpoints specified for application. Provide a list using $breakpoints, and $screen variables.'; 
	}
}

@mixin on-breakpoint($effect, $between: true) {
	@if not $effect {
		@error 'Effect argument must be specified.';
	}

	@each $breakpoint in $breakpoints {
		$index: list.index($breakpoints, $breakpoint);
			
		.#{$effect}-on-#{$breakpoint} {
			@include respond-to($breakpoint, up) {
				@content;
			}
		}

		@if ($between) {
			@if ($index < list.length($breakpoints)) {
				@for $i from $index + 1 through list.length($breakpoints) {
					$target-breakpoint: list.nth($breakpoints, $i);

					.#{$effect}-on-#{$breakpoint}-to-#{$target-breakpoint} {
						@include respond-to($breakpoint, false, $target-breakpoint) {
							@content;
						}
					}
				}
			}
		}
	}
}

@mixin outline-control($selector: '&:focus') {
	@content;
	outline: none;

	@at-root :global(html.user-is-tabbing) #{$selector} {
		outline: auto 2px Highlight; // for non-webkit browsers
		outline: auto 5px -webkit-focus-ring-color; // for webkit browsers
	}
}
