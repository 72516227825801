@import '../resources/vars';
@import '../resources/mixins';

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100dvh - var(--app-custom-top-nav-height) - var(--app-custom-bottom-nav-height));
  background: var(--template-video-background);

  --app-custom-template-column-start: row-start;
  --app-custom-template-column-end: row-end;

  @include respond-to(lg, up) {
    height: calc(100dvh - var(--app-custom-top-nav-height));
  }
}

.video {
  display: flex;
  flex: 1;

  padding: var(--template-video-padding);

  @include respond-to(sm, up) {
    padding: var(--template-video-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--template-video-padding-l);
  }

  & iframe {
    width: 100%;
    height: 100%;
  }
}
