@import '../resources/vars';
@import '../resources/mixins';

.container {
  background: var(--theme-page-header-bg-colour);

  @at-root :global(.sidebar) & {
    border-right: var(--sidebar-page-header-keyline);
  }

  &::after {
    border-bottom: var(--theme-page-header-keyline);
    display: block;

    @include respond-to(xss) {
      margin: var(--page-header-keyline-margin);
    }

    @at-root :global(.sidebar) & {
      margin: var(--page-header-keyline-margin);
    }

    @at-root .with-keyline#{&} {
      content: '';
    }
  }

  &.with-bg-image {
    background-image: var(--app-custom-page-header-bg-image);
    background-position: var(--app-custom-page-header-bg-position);
  }
}

.wrapper {
  display: flex;
  padding: var(--page-header-spacer-top) 0 var(--page-header-spacer-bottom);

  @include respond-to(sm, up) {
    padding: var(--page-header-spacer-top-m, var(--page-header-spacer-top)) 0
      var(--page-header-spacer-bottom-m, var(--page-header-spacer-bottom));
  }

  @include respond-to(lg, up) {
    padding: var(--page-header-spacer-top-l, var(--page-header-spacer-top)) 0
      var(--page-header-spacer-bottom-l, var(--page-header-spacer-bottom));
  }
}

.icon {
  margin: var(--page-header-icon-margin);

  svg {
    height: var(--theme-page-header-icon-size);
    width: var(--theme-page-header-icon-size);
  }

  path {
    fill: var(--theme-page-header-icon-colour);
  }
}

.icon-edit {
  align-items: center;
  background-color: var(--swatch-contnet-icon-bg);
  border-radius: 100%;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  path {
    fill: var(--swatch-content-icon-link);
  }
}

.body {
  --app-custom-template-column-start: edge-start;
  --app-custom-template-column-end: edge-end;
}

.body {
  flex: 1;
}

.heading {
  color: var(--theme-page-header-heading-colour);
  font-family: var(--page-header-heading-face);
  font-size: var(--page-header-heading-size);
  font-weight: var(--page-header-heading-weight);
  letter-spacing: var(--page-header-heading-letter-spacing);
  line-height: var(--page-header-heading-line-height);
  margin: 0 0 var(--page-header-spacer-below-heading) 0;
  text-align: var(--page-header-heading-text-align);
  text-transform: var(--page-header-heading-text-transform);
  text-shadow: var(--page-header-heading-text-shadow);

  @include respond-to(sm, up) {
    font-size: var(--page-header-heading-size-m, var(--page-header-heading-size));
    margin: 0 0 var(--page-header-spacer-below-heading-m, var(--page-header-spacer-below-heading)) 0;
  }

  @include respond-to(lg, up) {
    font-size: var(--page-header-heading-size-l, var(--page-header-heading-size));
    margin: 0 0 var(--page-header-spacer-below-heading-l, var(--page-header-spacer-below-heading)) 0;
  }
}

.subheading {
  color: var(--theme-page-header-sub-heading-colour);
  font-family: var(--page-header-sub-heading-face);
  font-size: var(--page-header-sub-heading-size);
  font-weight: var(--page-header-sub-heading-weight);
  letter-spacing: var(--page-header-sub-heading-letter-spacing);
  line-height: var(--page-header-sub-heading-line-height);
  text-align: var(--page-header-sub-heading-text-align);
  text-transform: var(--page-header-sub-heading-text-transform);
  text-shadow: var(--page-header-sub-heading-text-shadow);
  margin-top: var(--page-header-sub-heading-margin-top);

  @include respond-to(sm, up) {
    margin-top: var(--page-header-sub-heading-margin-top-m, var(--page-header-sub-heading-margin-top));
    font-size: var(--page-header-sub-heading-size-m, var(--page-header-sub-heading-size));
  }

  @include respond-to(lg, up) {
    margin-top: var(--page-header-sub-heading-margin-top-l, var(--page-header-sub-heading-margin-top));
    font-size: var(--page-header-sub-heading-size-l, var(--page-header-sub-heading-size));
  }
}

.image {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.category {
  width: fit-content;

  padding: var(--page-header-category-padding);
  margin: var(--page-header-category-margin);
  border: var(--page-header-category-border);
  border-radius: var(--page-header-category-border-radius);
  background: var(--page-header-category-background);
  box-shadow: var(--page-header-category-box-shadow);

  @include with-styled-text(page-header-category-label);

  @include respond-to(sm, up) {
    font-size: var(--page-header-category-label-font-size-m);
    margin: var(--page-header-category-margin-m, var(--page-header-category-margin));
  }

  @include respond-to(lg, up) {
    font-size: var(--page-header-category-label-font-size-l);
    margin: var(--page-header-category-margin-l, var(--page-header-category-margin));
  }
}

.nav-back {
  align-items: center;
  display: var(--template-articles-metabox-back-display);
  padding: var(--template-articles-metabox-back-padding);
  margin: var(--template-articles-metabox-back-margin);

  p {
    color: var(--template-articles-metabox-back-text-color);
    line-height: var(--template-articles-metabox-back-text-line-height);
    margin: 0;
  }

  svg {
    height: var(--template-articles-metabox-back-icon-size);
    width: var(--template-articles-metabox-back-icon-size);
  }

  path {
    fill: var(--template-articles-metabox-back-icon-colour);
  }
}
