@import '../resources/vars';
@import '../resources/mixins';

.button,
.button:hover,
.button:active,
.button:visited {
  display: flex;
  flex: 1 1 auto;
  align-items: var(--page-actions-button-align-items, center);
  justify-content: var(--page-actions-button-justify-content, center);
  flex-direction: var(--page-actions-button-flex-direction, row);
  height: var(--page-actions-button-height);
  max-width: var(--app-page-actions-button-width, var(--page-actions-button-max-width));
  gap: var(--page-actions-button-gap);

  padding: var(--app-page-actions-button-padding, var(--page-actions-button-padding));
  margin: var(--app-page-actions-button-margin, var(--page-actions-button-margin));
  background: var(--app-page-actions-button-background, var(--page-actions-button-background));
  border: var(--app-page-actions-button-border,var(--page-actions-button-border));
  border-radius: var(--page-actions-button-border-radius);
  box-shadow: var(--app-page-actions-button-box-shadow, var(--page-actions-button-box-shadow));

  @include respond-to(sm, up) {
    height: var(--page-actions-button-height-m);
    max-width: var(--app-page-actions-button-width, var(--page-actions-button-max-width-m));
    margin: var(--page-actions-button-margin-m)
  }

  @include respond-to(lg, up) {
    height: var(--page-actions-button-height-l);
    max-width: var(--app-page-actions-button-width, var(--page-actions-button-max-width-l));
    margin: var(--page-actions-button-margin-l)
  }
}

.label {
  @include with-styled-text(page-actions-button-label);
  color: var(--app-page-actions-button-color, var(--page-actions-button-label-colour));
  font-weight: var(--app-page-actions-button-font-weight, var(--page-actions-button-label-font-weight));
  font-size: var(--app-page-actions-button-font-size, var(--page-actions-button-label-font-size));
  font-family: var(--app-page-actions-button-font-family, var(--page-actions-button-label-font-family));
  font-style: var(--app-page-actions-button-font-style, var(--page-actions-button-label-font-style));
  line-height: var(--app-page-actions-button-line-height, var(--page-actions-button-label-line-height));
  letter-spacing: var(--app-page-actions-button-letter-spacing, var(--page-actions-button-label-letter-spacing));
  text-transform: var(--app-page-actions-button-text-transform, var(--page-actions-button-label-text-transform));
  text-decoration: var(--app-page-actions-button-text-decoration, var(--page-actions-button-label-text-decoration));

  @include respond-to(sm, up) {
    font-size: var(--app-page-actions-button-font-size, var(--page-actions-button-label-font-size-m));
  }

  @include respond-to(lg, up) {
    font-size: var(--app-page-actions-button-font-size, var(--page-actions-button-label-font-size-l));
  }

  margin: var(--page-actions-button-label-margin);
  padding: var(--page-actions-button-label-padding);
}

.icon {
  height: var(--page-actions-button-icon-height, var(--btn-icon-height));
  width: var(--page-actions-button-icon-width, var(--btn-icon-width));
  border: var(--page-actions-button-icon-border, var(--btn-icon-border));
  background-color: var(--page-actions-button-icon-bg-color, var(--app-btn-icon-bg-color));

  svg {
    width: var(--page-actions-button-icon-size);
    height: var(--page-actions-button-icon-size);
    margin: var(--page-actions-button-icon-margin);
  }

  path {
    fill: var(--app-page-actions-button-icon-colour, var(--page-actions-button-icon-colour));
  }
}

.major {
  --app-page-actions-button-background: var(--btn-major-background);
  --app-page-actions-button-border: var(--btn-major-border);
  --app-page-actions-button-box-shadow: var(--btn-major-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-major-icon-color);
  --app-page-actions-button-font-weight: var(--btn-major-label-font-weight);
  --app-page-actions-button-color: var(--btn-major-label-color);
  --app-page-actions-button-font-style: var(--btn-major-label-font-style);
  --app-page-actions-button-text-decoration: var(--btn-major-label-text-decoration);
}
.minor {
  --app-page-actions-button-background: var(--btn-minor-background);
  --app-page-actions-button-border: var(--btn-minor-border);
  --app-page-actions-button-box-shadow: var(--btn-minor-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-minor-icon-color);
  --app-page-actions-button-font-weight: var(--btn-minor-label-font-weight);
  --app-page-actions-button-color: var(--btn-minor-label-color);
  --app-page-actions-button-font-style: var(--btn-minor-label-font-style);
  --app-page-actions-button-text-decoration: var(--btn-minor-label-text-decoration);
}
.negative {
  --app-page-actions-button-background: var(--btn-negative-background);
  --app-page-actions-button-border: var(--btn-negative-border);
  --app-page-actions-button-box-shadow: var(--btn-negative-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-negative-icon-color);
  --app-page-actions-button-font-weight: var(--btn-negative-label-font-weight);
  --app-page-actions-button-color: var(--btn-negative-label-color);
  --app-page-actions-button-font-style: var(--btn-negative-label-font-style);
  --app-page-actions-button-text-decoration: var(--btn-negative-label-text-decoration);
}
.destructive {
  --app-page-actions-button-background: var(--btn-destructive-background);
  --app-page-actions-button-border: var(--btn-destructive-border);
  --app-page-actions-button-box-shadow: var(--btn-destructive-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-destructive-icon-color);
  --app-page-actions-button-font-weight: var(--btn-destructive-label-font-weight);
  --app-page-actions-button-color: var(--btn-destructive-label-color);
  --app-page-actions-button-font-style: var(--btn-destructive-label-font-style);
  --app-page-actions-button-text-decoration:  var(--btn-destructive-label-text-decoration);
}
.special {
  --app-page-actions-button-background: var(--btn-special-background);
  --app-page-actions-button-border: var(--btn-special-border);
  --app-page-actions-button-box-shadow: var(--btn-special-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-special-icon-color);
  --app-page-actions-button-font-weight: var(--btn-special-label-font-weight);
  --app-page-actions-button-color: var(--btn-special-label-color);
  --app-page-actions-button-font-style: var(--btn-special-label-font-style);
  --app-page-actions-button-text-decoration: var(--btn-special-label-text-decoration);
}
.diminished {
  --app-page-actions-button-background: var(--btn-diminished-background);
  --app-page-actions-button-border: var(--btn-diminished-border);
  --app-page-actions-button-box-shadow: var(--btn-diminished-box-shadow);
  --app-page-actions-button-icon-colour: var(--btn-diminished-icon-color);
  --app-page-actions-button-font-weight: var(--btn-diminished-label-font-weight);
  --app-page-actions-button-color: var(--btn-diminished-label-color);
  --app-page-actions-button-font-style: var(--btn-diminished-label-font-style);
  --app-page-actions-button-text-decoration: var(--btn-diminished-label-text-decoration);
}
