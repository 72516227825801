@import '../../resources/vars';
@import '../../resources/mixins';

.block {
  height: var(--feedback-block-height);
  width: var(--feedback-block-width);
  padding: var(--feedback-block-padding);
  margin: var(--feedback-block-margin);
  background: var(--feedback-block-background);
  border: var(--feedback-block-border);
  border-radius: var(--feedback-block-border-radius);
  box-shadow: var(--feedback-block-box-shadow);
}

.content {
  height: var(--feedback-content-height);
  width: var(--feedback-content-width);
  padding: var(--feedback-content-padding);
  margin: var(--feedback-content-margin);
  background: var(--feedback-content-background);
  border: var(--feedback-content-border);
  border-radius: var(--feedback-content-border-radius);
  box-shadow: var(--feedback-content-box-shadow);
}

.heading {
  @include with-styled-text(feedback-heading);
  height: var(--feedback-heading-height);
  width: var(--feedback-heading-width);
  padding: var(--feedback-heading-padding);
  margin: var(--feedback-heading-margin);
  background: var(--feedback-heading-background);
  border: var(--feedback-heading-border);
  border-radius: var(--feedback-heading-border-radius);
  box-shadow: var(--feedback-heading-box-shadow);
};

.answers {
  display: flex;
  flex-direction: var(--feedback-items-flex-direction);
  align-items: var(--feedback-items-align-items);
  justify-content: var(--feedback-items-justify-content);
  height: var(--feedback-items-height);
  width: var(--feedback-items-width);
  padding: var(--feedback-items-padding);
  margin: var(--feedback-items-margin);
  background: var(--feedback-items-background);
  border: var(--feedback-items-border);
  border-radius: var(--feedback-items-border-radius);
  box-shadow: var(--feedback-items-box-shadow);
}

.answer {
  display: flex;
  flex-direction: var(--feedback-item-flex-direction);
  align-items: var(--feedback-item-align-items);
  justify-content: var(--feedback-item-justify-content);
  height: var(--feedback-item-height);
  width: var(--feedback-item-width);
  padding: var(--feedback-item-padding);
  margin: var(--feedback-item-margin, 0 8px);
  --app-btn-icon-bg-color: var(--feedback-item-background);
  border: var(--feedback-item-border);
  border-radius: var(--feedback-item-border-radius);
  box-shadow: var(--feedback-item-box-shadow);

  &[aria-disabled="true"] {
    opacity: 1;
    cursor: not-allowed;
  }
}

.unanswered {
  display: flex;
  align-items: center;
  justify-content: center;

  --feedback-icon-container-background: var(--feedback-unanswered-icon-container-bg-colour);
  --feedback-icon-colour: var(--feedback-unanswered-icon-colour);
  --feedback-icon-container-border: var(--feedback-unanswered-icon-border);
  --feedback-icon-container-box-shadow: var(--feedback-unanswered-icon-box-shadow);

  background: var(--feedback-icon-container-background);
  height: var(--feedback-icon-container-height);
  width: var(--feedback-icon-container-width);
  margin: var(--feedback-icon-container-margin);
  padding: var(--feedback-icon-container-padding);
  border: var(--feedback-icon-container-border);
  border-radius: var(--feedback-icon-container-border-radius);
  box-shadow: var(--feedback-icon-container-box-shadow);

  path {
    fill: var(--feedback-icon-colour);
  }

  svg {
    height: var(--feedback-icon-size);
    width: var(--feedback-icon-size);
  }
}

.answered {
  --feedback-icon-container-background: var(--feedback-answered-icon-container-bg-colour);
  --feedback-icon-colour: var(--feedback-answered-icon-colour);
  --feedback-icon-container-border: var(--feedback-answered-icon-border);
  --feedback-icon-container-box-shadow: var(-feedback-answered-icon-box-shadow);
}

.selected {
  --feedback-icon-container-background: var(--feedback-selected-icon-container-bg-colour);
  --feedback-icon-colour: var(--feedback-selected-icon-colour);
  --feedback-icon-container-border: var(--feedback-selected-icon-border);
  --feedback-icon-container-box-shadow: var(--feedback-selected-icon-box-shadow);
}

.label {
  width: 100%;
  @include with-styled-text(feedback-item-label);
  margin: var(--feedback-item-label-margin);
  padding: var(--feedback-item-label-padding);
}
