@import '../resources/vars';
@import '../resources/mixins';

.container {
  max-width: var(--forms-wheel-container-max-width);
  width: 100%;
}

.text,
.wrapper,
.window {
  display: flex;
  justify-content: center;
}

.text {
  margin-bottom: var(--forms-wheel-text-margin-bottom);
}

.wrapper {
  position: relative;
}

.indicator {
  background-color: var(--forms-wheel-indicator-on-colour);
  border-color: transparent transparent var(--forms-wheel-indicator-on-colour) transparent;
  height: var(--forms-wheel-indicator-height);
  pointer-events: none;
  position: absolute;
  top: var(--forms-wheel-indicator-align);
  transform: translateY(var(--forms-wheel-indicator-align));
  width: 4px;
  z-index: var(--app-z-index-layer-1);

  &.error {
    background-color: var(--forms-wheel-indicator-off-colour);
    border-color: transparent transparent var(--forms-wheel-indicator-off-colour) transparent;
  }

  &::before {
    border-color: inherit;
    border-style: solid;
    border-width: 0 calc(var(--forms-wheel-indicator-arrowhead-width) / 2) var(--forms-wheel-indicator-arrowhead-height)
      calc(var(--forms-wheel-indicator-arrowhead-width) / 2);
    content: '';
    display: var(--forms-wheel-indicator-arrowhead-display);
    height: 0;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, calc((var(--forms-wheel-indicator-arrowhead-height) / 2) * -1)) rotate(360deg);
    width: 0;
  }
}

.stepper {
  align-self: center;
  background-color: var(--forms-wheel-stepper-bg-colour);
  border: var(--forms-wheel-stepper-border);
  border-radius: var(--forms-wheel-stepper-border-radius);
  height: var(--forms-wheel-stepper-height);
  margin: 1px 0;
  width: var(--forms-wheel-stepper-width);
  box-shadow: var(-—forms-wheel-stepper-box-shadow);
}

.stepper {
  align-items: center;
  display: flex;
  justify-content: center;

  svg {
    height: var(--forms-wheel-stepper-icon-size);
    width: var(--forms-wheel-stepper-icon-size);
  }

  path {
    fill: var(--forms-wheel-stepper-icon-on-colour);
  }

  &:first-child {
    margin-right: var(--forms-wheel-stepper-margin);
  }

  &:last-child {
    margin-left: var(--forms-wheel-stepper-margin);
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
    opacity: var(--forms-wheel-stepper-disabled-opacity);

    path {
      fill: var(--forms-wheel-stepper-icon-off-colour);
    }
  }
}

.body {
  border: var(--forms-wheel-grip-container-border);
  border-radius: var(--forms-wheel-grip-container-border-radius);
  flex: 1;
  overflow: hidden;
  pointer-events: none;
  position: relative;
  z-index: 0;

  &::before,
  &::after {
    background-color: var(--forms-wheel-frame-bg-colour);
    background-image: var(--forms-wheel-frame-bg-gradient);
    content: '';
    display: var(--forms-wheel-frame-display);
    height: var(--forms-wheel-frame-height);
    position: relative;
    width: 100%;
    z-index: 2;
  }

  &::before {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    box-shadow: var(--forms-wheel-top-frame-shadow);
  }

  &::after {
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    box-shadow: var(--forms-wheel-bottom-frame-shadow);
  }
}

.fader {
  height: 100%;
  position: absolute;
  top: 0;
  width: 25%;
  z-index: 1;

  &.left {
    background-image: linear-gradient(
      to right,
      rgba(var(--forms-wheel-fade-colour), 1),
      rgba(var(--forms-wheel-fade-colour), 0)
    );
    left: 0;
  }

  &.right {
    background-image: linear-gradient(
      to left,
      rgba(var(--forms-wheel-fade-colour), 1),
      rgba(var(--forms-wheel-fade-colour), 0)
    );
    right: 0;
  }
}

.window {
  background-color: var(--forms-wheel-grip-container-bg-colour);
  background-image: var(--forms-wheel-grip-container-bg-gradient);
  box-shadow: var(--forms-wheel-grip-container-shadow);

  cursor: pointer;
  perspective: 800px;
}

.rotator {
  @extend %preserve-3d;
  height: var(--forms-wheel-grip-container-height);
  transform: none;
  will-change: transform;
}

.grip {
  backface-visibility: hidden;
  height: 100%;
  pointer-events: all;
  position: absolute;
  user-select: none;
  font-size: 0;
}

.grip {
  background-image: linear-gradient(to bottom, var(--forms-wheel-grips-colour), var(--forms-wheel-grips-colour));
  background-position: center var(--forms-wheel-grips-vertical-position);
  background-repeat: no-repeat;
  background-size: var(--forms-wheel-grips-width) var(--forms-wheel-grips-height);
}

.left {
  flex-basis: var(--forms-wheel-label-width);
}

.left {
  --app-input-label-direction: var(--forms-wheel-label-direction);
  --app-input-label-justify-content: var(--forms-wheel-label-justify-content);
  --app-input-label-align-items: var(--forms-wheel-label-align-items);
  --app-input-label-margin: var(--forms-wheel-label-margin);

  --app-input-informer-margin: var(--forms-wheel-informer-margin);
}

.label {
  color: var(--forms-wheel-label-colour);
  font-family: var(--forms-wheel-label-font-face);
  font-size: var(--forms-wheel-label-font-size);
  font-style: var(--forms-wheel-label-font-style);
  font-weight: var(--forms-wheel-label-font-weight);
  letter-spacing: var(--forms-wheel-label-letter-spacing);
  line-height: var(--forms-wheel-label-line-height);
  text-align: var(--forms-wheel-label-text-align);
  text-transform: var(--forms-wheel-label-text-transform);

  @include respond-to(sm, up) {
    font-size: var(--forms-wheel-label-font-size-m, var(--forms-wheel-label-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--forms-wheel-label-font-size-l, var(--forms-wheel-label-font-size));
  }
}

.right {
  flex-basis: var(--forms-wheel-computed-width);
}

.computed {
  color: var(--forms-wheel-computed-colour);
  font-family: var(--forms-wheel-computed-font-face);
  font-size: var(--forms-wheel-computed-font-size);
  font-style: var(--forms-wheel-computed-font-style);
  font-weight: var(--forms-wheel-computed-font-weight);
  letter-spacing: var(--forms-wheel-computed-letter-spacing);
  line-height: var(--forms-wheel-computed-line-height);
  text-align: var(--forms-wheel-computed-text-align);
  text-transform: var(--forms-wheel-computed-text-transform);
}

.center {
  flex-basis: var(--forms-wheel-value-width);
}

.value {
  color: var(--forms-wheel-value-colour);
  font-family: var(--forms-wheel-value-font-face);
  font-size: var(--forms-wheel-value-font-size);
  font-style: var(--forms-wheel-value-font-style);
  font-weight: var(--forms-wheel-value-font-weight);
  letter-spacing: var(--forms-wheel-value-letter-spacing);
  line-height: var(--forms-wheel-value-line-height);
  text-align: var(--forms-wheel-value-text-align);
  text-transform: var(--forms-wheel-value-text-transform);
}
