@import '../resources/vars';
@import '../resources/mixins';

@include respond-to(lg, up) {
  .header {
    --app-custom-template-column-start: var(--app-template-checklist-header-column-start);
    --app-custom-template-column-end: var(--app-template-checklist-header-column-end);
  }

  .body {
    --app-custom-template-column-start: var(--app-template-checklist-body-column-start);
    --app-custom-template-column-end: var(--app-template-checklist-body-column-end);
  }
}
