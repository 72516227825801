@import '../../resources/vars';
@import '../../resources/mixins';

.image{
  width: 100%;
  height: 100%;

  aspect-ratio: 16/9;
  flex: 1 1;

  &.fill{
    object-fit: fill;
  }
  &.contain{
    object-fit: contain;
  }
  &.cover{
    object-fit: cover;
  }
  &.none{
    object-fit: none;
  }
  &.scale-down{
    object-fit: scale-down;
  }

}

