@import '~@packages/ui-kit/styles/mixins';

@mixin text-zone {
  &.content {
    &.heading {
      color: var(--swatch-content-text-headings);
    }

    &.strong {
      color: var(--swatch-content-text-strong);
    }

    &.subtle {
      color: var(--swatch-content-text-subtle);
    }

    &.link {
      color: var(--swatch-content-text-link);
    }
  }

  &.card {
    &.heading {
      color: var(--swatch-card-text-headings);
    }

    &.strong {
      color: var(--swatch-card-text-strong);
    }

    &.subtle {
      color: var(--swatch-card-text-subtle);
    }

    &.link {
      color: var(--swatch-card-text-link);
    }
  }

  &.hero {
    &.heading {
      color: var(--swatch-hero-text-headings);
    }

    &.strong {
      color: var(--swatch-hero-text-strong);
    }

    &.subtle {
      color: var(--swatch-hero-text-subtle);
    }

    &.link {
      color: var(--swatch-hero-text-link);
    }
  }
}

@mixin rewrite-styled-text-vars($rewritable-prefix, $prefix, $exclude...) {
  $font-text-properties: color, colour, font-size, font-family, font-weight, font-style, line-height, letter-spacing,
    text-transform, text-align;

  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      --#{$rewritable-prefix}-#{$prop}: var(--#{$prefix}-#{$prop});
    }
  }
}

@mixin with-styled-text($prefix, $exclude...) {
  $font-text-properties: color, font-size, font-family, font-weight, font-style, line-height, letter-spacing,
    text-transform, text-align, text-shadow;

  $font-text-properties-map: (
    'color': 'colour'
  );

  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      $value: map-get($font-text-properties-map, $prop) or $prop;

      #{$prop}: var(--#{$prefix}-#{$value});
    }
  }
}

@mixin with-accounts($property) {
  $accounts: (
    htbISA: --btn-account-htb-isa-color,
    lifetimeISA: --btn-account-lisa-color,
    regularSavings: --btn-account-regular-savings-color,
    cashISA: --btn-account-cash-isa-color,
    stockAndSharesISA: --btn-account-stock-and-shares-isa-color,
    current: --btn-account-current-color,
    others: --btn-account-others-color
  );

  @each $name, $value in $accounts {
    &.#{$name} {
      #{$property}: var(#{$value});
      @content;
    }
  }
}

@mixin with-advert-item-overrides($option){
  //Container overrides
  --app-custom-tl-item-margin: var(--targeted-links-item-#{$option}-margin);
  --app-custom-tl-item-background: var(--targeted-links-item-#{$option}-background);
  --app-custom-tl-item-border: var(--targeted-links-item-#{$option}-border);
  --app-custom-tl-item-border-radius: var(--targeted-links-item-#{$option}-border-radius);
  --app-custom-tl-item-box-shadow: var(--targeted-links-item-#{$option}-box-shadow);
  --app-custom-tl-item-flex-direction: var(--targeted-links-item-#{$option}-flex-direction);
  --app-custom-tl-item-flex-direction-m: var(--targeted-links-item-#{$option}-flex-direction-m);
  --app-custom-tl-item-flex-direction-l: var(--targeted-links-item-#{$option}-flex-direction-l);
  --app-custom-tl-item-padding: var(--targeted-links-item-#{$option}-padding);
  --app-custom-tl-item-padding-m: var(--targeted-links-item-#{$option}-padding-m);
  --app-custom-tl-item-padding-l: var(--targeted-links-item-#{$option}-padding-l);
  --app-custom-tl-item-gap: var(--targeted-links-item-#{$option}-gap);
  --app-custom-tl-item-gap-m: var(--targeted-links-item-#{$option}-gap-m);
  --app-custom-tl-item-gap-l: var(--targeted-links-item-#{$option}-gap-l);

  //Cover image overrides
  --app-custom-tl-cover-img-padding: var(--targeted-links-item-cover-image-#{$option}-padding);
  --app-custom-tl-cover-img-padding-m: var(--targeted-links-item-cover-image-#{$option}-padding-m);
  --app-custom-tl-cover-img-padding-l: var(--targeted-links-item-cover-image-#{$option}-padding-l);
  --app-custom-tl-cover-img-height: var(--targeted-links-item-cover-image-#{$option}-height);
  --app-custom-tl-cover-img-height-m: var(--targeted-links-item-cover-image-#{$option}-height-m);
  --app-custom-tl-cover-img-height-l: var(--targeted-links-item-cover-image-#{$option}-height-l);
  --app-custom-tl-cover-img-width: var(--targeted-links-item-cover-image-#{$option}-width);
  --app-custom-tl-cover-img-width-m: var(--targeted-links-item-cover-image-#{$option}-width-m);
  --app-custom-tl-cover-img-width-l: var(--targeted-links-item-cover-image-#{$option}-width-l);
  --app-custom-tl-cover-img-object-fit: var(--targeted-links-item-cover-image-#{$option}-object-fit);

  //Body overrides
  --app-custom-tl-item-body-padding: var(--targeted-links-item-body-#{$option}-padding);
  --app-custom-tl-item-body-padding-m: var(--targeted-links-item-body-#{$option}-padding-m);
  --app-custom-tl-item-body-padding-l: var(--targeted-links-item-body-#{$option}-padding-l);
  --app-custom-tl-item-body-gap: var(--targeted-links-item-body-#{$option}-gap);
  --app-custom-tl-item-body-gap-m: var(--targeted-links-item-body-#{$option}-gap-m);
  --app-custom-tl-item-body-gap-l: var(--targeted-links-item-body-#{$option}-gap-l);

  //Header overrides
  --app-custom-tl-item-header-padding: var(--targeted-links-item-header-#{$option}-padding);
  --app-custom-tl-item-header-padding-m: var(--targeted-links-item-header-#{$option}-padding-m);
  --app-custom-tl-item-header-padding-l: var(--targeted-links-item-header-#{$option}-padding-l);
  --app-custom-tl-item-header-gap: var(--targeted-links-item-header-#{$option}-gap);
  --app-custom-tl-item-header-gap-m: var(--targeted-links-item-header-#{$option}-gap-m);
  --app-custom-tl-item-header-gap-l: var(--targeted-links-item-header-#{$option}-gap-l);
  --app-custom-tl-item-header-justify-content: var(--targeted-links-item-header-#{$option}-justify-content);
  --app-custom-tl-item-header-justify-content-m: var(--targeted-links-item-header-#{$option}-justify-content-m);
  --app-custom-tl-item-header-justify-content-l: var(--targeted-links-item-header-#{$option}-justify-content-l);

  //Icon overrides
  --app-custom-tl-item-icon-size: var(--targeted-links-item-icon-#{$option}-size);
  --app-custom-tl-item-icon-colour: var(--targeted-links-item-icon-#{$option}-colour);
  --app-custom-tl-item-icon-bg-padding: var(--targeted-links-item-icon-#{$option}-bg-padding);
  --app-custom-tl-item-icon-bg-border-radius: var(--targeted-links-item-icon-#{$option}-bg-border-radius);
  --app-custom-tl-item-icon-bg-background: var(--targeted-links-item-icon-#{$option}-bg-background);
  --app-custom-tl-item-icon-bg-border: var(--targeted-links-item-icon-#{$option}-bg-border);

  //Heading overrides
  @include rewrite-styled-text-vars(app-custom-tl-item-heading, targeted-links-item-heading-#{$option});
  --app-custom-tl-item-heading-font-size: var(--targeted-links-item-heading-#{$option}-font-size);
  --app-custom-tl-item-heading-font-size-m: var(--targeted-links-item-heading-#{$option}-font-size-m);
  --app-custom-tl-item-heading-font-size-l: var(--targeted-links-item-heading-#{$option}-font-size-l);
  --app-custom-tl-item-heading-padding: var(--targeted-links-item-heading-#{$option}-padding);
  --app-custom-tl-item-heading-padding-m: var(--targeted-links-item-heading-#{$option}-padding-m);
  --app-custom-tl-item-heading-padding-l: var(--targeted-links-item-heading-#{$option}-padding-l);

  //Paragraph overrides
  @include rewrite-styled-text-vars(app-custom-tl-item-p, targeted-links-item-p-#{$option});
  --app-custom-tl-item-p-font-size: var(--targeted-links-item-p-#{$option}-font-size);
  --app-custom-tl-item-p-font-size-m: var(--targeted-links-item-p-#{$option}-font-size-m);
  --app-custom-tl-item-p-font-size-l: var(--targeted-links-item-p-#{$option}-font-size-l);
  --app-custom-tl-item-p-padding: var(--targeted-links-item-p-#{$option}-padding);
  --app-custom-tl-item-p-padding-m: var(--targeted-links-item-p-#{$option}-padding-m);
  --app-custom-tl-item-p-padding-l: var(--targeted-links-item-p-#{$option}-padding-l);

  // Inline image overrides
  --app-custom-tl-inline-img-padding: var(--targeted-links-item-inline-image-#{$option}-padding);
  --app-custom-tl-inline-img-padding-m: var(--targeted-links-item-inline-image-#{$option}-padding-m);
  --app-custom-tl-inline-img-padding-l: var(--targeted-links-item-inline-image-#{$option}-padding-l);
  --app-custom-tl-inline-img-height: var(--targeted-links-item-inline-image-#{$option}-height);
  --app-custom-tl-inline-img-height-m: var(--targeted-links-item-inline-image-#{$option}-height-m);
  --app-custom-tl-inline-img-height-l: var(--targeted-links-item-inline-image-#{$option}-height-l);

  //Buttons container overrides
  --app-custom-tl-buttons-gap: var(--targeted-links-item-buttons-#{$option}-gap);
  --app-custom-tl-buttons-gap-m: var(--targeted-links-item-buttons-#{$option}-gap-m);
  --app-custom-tl-buttons-gap-l: var(--targeted-links-item-buttons-#{$option}-gap-l);
  --app-custom-tl-buttons-align: var(--targeted-links-item-buttons-align-#{$option}-items);
  --app-custom-tl-buttons-align-m: var(--targeted-links-item-buttons-align-#{$option}-items-m);
  --app-custom-tl-buttons-align-l: var(--targeted-links-item-buttons-align-#{$option}-items-l);

  //Button overrides
  --app-custom-tl-button-width: var(--targeted-links-item-button-#{$option}-width);
}
