@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: flex;
  margin: var(--app-page-actions-margin, var(--page-actions-margin));
  padding: var(--page-actions-padding);
  border-top: var(--page-actions-border-top);
  border-bottom: var(--page-actions-border-bottom);
  flex-direction: column;

  @include respond-to(sm, up) {
    flex-direction: var(--page-actions-flex-direction-m, row);
    margin: var(--page-actions-margin-m, var(--page-actions-margin));
    padding: var(--page-actions-padding-m, var(--page-actions-padding));
  }

  @include respond-to(lg, up) {
    flex-direction: var(--page-actions-flex-direction-l, row);
    margin: var(--page-actions-margin-l, var(--page-actions-margin));
    padding: var(--page-actions-padding-l, var(--page-actions-padding));
  }
}

.label {
  @include with-styled-text(page-actions-label);

  @include respond-to(sm, up) {
    font-size: var(--page-actions-label-font-size-m, var(--page-actions-label-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--page-actions-label-font-size-l, var(--page-actions-label-font-size));
  }
}

.buttons {
  display: flex;
  width: 100%;
  flex-direction: var(--page-actions-buttons-flex-direction);
  align-items: var(--page-actions-buttons-align-items);
  justify-content: var(--page-actions-buttons-justify-content);
  gap: var(--page-actions-gap);

  @include respond-to(sm, up) {
    flex-direction: var(--page-actions-buttons-flex-direction-m);
    align-items: var(--page-actions-buttons-align-items-m);
    justify-content: var(--page-actions-buttons-justify-content-m);
    gap: var(--page-actions-gap-m, var(--page-actions-gap));
  }

  @include respond-to(lg, up) {
    flex-direction: var(--page-actions-buttons-flex-direction-l);
    align-items: var(--page-actions-buttons-align-items-l);
    justify-content: var(--page-actions-buttons-justify-content-l);
    gap: var(--page-actions-gap-l, var(--page-actions-gap));
  }
}
